import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { useLocation } from "@reach/router"
import { Layout, Col, Row } from "antd"
import PublicLayout from "../components/PublicLayout"
import Categories from "./components/blog/categories"
import Posts from "./components/blog/posts"
import Header from "./components/blog/header"
import SEO from "../components/seo"
import { useTranslation } from "react-i18next"
import imageUrlReplace from "./components/blog/imageUrlReplace"

const { Content } = Layout

const CategoryPage = (props) => {
  const {
    data: {
      wpgraphql: { posts, categories },
    },
    pageContext: { categoryIds },
  } = props;

  const location = useLocation()
  const { t } = useTranslation()
  const [filteredCategories, setFilteredCategories] = useState({});

  useEffect(() => {
    if (categories?.nodes && categoryIds) {
      const filteredCategories = categories.nodes.filter(category => categoryIds.includes(category.id));
      setFilteredCategories(filteredCategories);
    }
  }, []);

  const capitalizeChars = (pathName) => {
    let string = pathName.toLowerCase().split('/')[2];

    string = string.includes('-') ? string.split('-') : string.split(' ');
    string = string.map(str => str.charAt(0).toUpperCase() + str.substring(1));

    if (string.includes('Cmt')) {
      string[1] = string[1].toUpperCase();
    }

    if (string.includes('Tecnologia')) {
      string[0] = 'Tecnología';
    }

    string = string.join(' ');
    return string;
  };

  const schemaExtraData = [{
    "@type": "Organization",
    "logo": "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    "sameAs": [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador"
    ],
  }]

  
  return (
    <PublicLayout page="blog">
      <SEO
        title={`${(location?.pathname) &&
          `${capitalizeChars(location.pathname)} ${t('seo.category.title')}`}`
        }
        description={(location?.pathname) &&
          `${capitalizeChars(location.pathname)}${t('seo.category.description')}`
        }
        image={imageUrlReplace(posts.nodes.image?.featuredImage?.node?.sourceUrl)}
        dataLayer={{
          userId: "",
          pageId: "560",
          pageType: "page",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Layout className="blog-container">
        <Content>
          <Row justify="center">
            <Col lg={24} xs={24}>
              <Header
                t={t}
                category={
                  (location?.pathname) && `${capitalizeChars(location.pathname)}`
                }
              />
              {
                filteredCategories.length > 0 &&
                  <Categories categories={filteredCategories} />
              }
              <Posts posts={posts} />
            </Col>
          </Row>
        </Content>
      </Layout>
    </PublicLayout>
  )
}

export default CategoryPage;

export const postQuery = graphql`
  query GET_POSTS_BY_CATEGORY($categoryName: String, $postIds: [ID], $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    wpgraphql {
      categories {
        nodes {
          id
          name
          slug
          description
        }
      }
      posts(where: { categoryName: $categoryName, in: $postIds }) {
        nodes {
          id
          title
          date
          excerpt
          content
          slug
          categories {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
}
`
